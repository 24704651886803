<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<div class="font-semibold space-y-3">
								<p>NOTE: PAYPAL IS NO LONGER AVAILABLE. PLEASE DO NOT EMAIL US ASKING FOR PAYPAL.</p>
								<p><span class="bg-yellow-600">USE A COMPUTER WITH CHROME BROWSER OR FIREFOX BROWSER. INCOGNITO MODE WILL NOT WORK</span></p>
							</div>
							<hr />
							<div class="space-y-5">
								<p>
									1.To renew visit our website at the following link
									<a :href="`${$store.state.brandInfo.siteLink}/#pricing`" target="_blank" class="text-blue-600 font-semibold"
										>{{ $store.state.brandInfo.siteLink }}/#pricing</a
									>
								</p>
								<p>2.Select the subscription period you want by clicking the "Subscribe now!" button</p>
								<div class="space-y-5">
									<img src="./../../../assets/images/mini-icon/pricing-table.png" alt="pricing-table" />
									<p>3. <span class="font-semibold">You will be taken to screen below then click login</span></p>
									<img src="../../../assets/images/mini-icon/account-area.png" alt="my-account-area" />
									<p>4.Type in your <span class="font-semibold">Username & Password</span> and then click the SIGN IN.</p>
									<div>
										<img class="pt-10" src="./../../../assets/images/mini-icon/sign-in.png" alt="Sign In" />
									</div>
									<p class="font-semibold">7. <span class="bg-yellow-400">ONCE YOU HAVE SUCCESSFULLY LOGGED IN YOU WILL BE TAKEN TO SCREEN BELOW.</span></p>
									<img src="./../../../assets/images/mini-icon/profile-image.png" alt="profile-image" />
									<p class="font-semibold">8. <span class="bg-yellow-300">SELECT SUBSCRIBE NOW AND YOU WILL BE SENT TO SCREEN BELOW</span></p>
									<img src="./../../../assets/images/mini-icon/subscription-analytics.png" alt="subscription-analytics" />
									<div class="space-y-1">
										<p>9. <span class="font-semibold bg-yellow-300">FROM HERE</span></p>
										<p><span class="font-semibold bg-yellow-300">SIMPLY CHOOSE THE PACKAGE YOU WANT THEN CLICK ON CHOOSE PLAN.</span></p>
									</div>
									<p class="py-10"><span class="font-semibold bg-yellow-300">NEXT PAGE WILL TAKE YOU TO OUR PAYMENT SCREEN BELOW</span></p>
									<img src="./../../../assets/images/mini-icon/select-payment-method-img.png" alt="select-payment-method-img" />
									<div class="font-semibold space-y-7">
										<p>10. <span class="bg-yellow-300">CHOOSE YOUR PAYMENT METHOD.</span></p>
										<p>
											<span class="bg-yellow-300">{{ isPizza ? '' : 'CURRENTLY CREDIT/DEBIT CARD AND ' }}BITCOIN ARE THE ONLY ACCEPTED PAYMENT METHODS.</span>
										</p>
										<p><span class="bg-yellow-300">ADDITIONAL PAYMENT METHODS COMING SOON .</span></p>
										<p>
											<span class="bg-yellow-300">{{ isPizza ? '' : 'FOR THIS TUTORIAL WE WILL SELECT CARD.' }}</span>
										</p>
									</div>
									<img class="py-10" v-if="!isPizza" src="./../../../assets/images/mini-icon/pay-form.png" alt="pay-form" />
									<p class="font-semibold" v-if="!isPizza"><span class="bg-yellow-300">SIMPLY FILL OUT YOUR PAYMENT INFORMATION THEN CLICK SUBMIT AND</span></p>
									<p class="font-semibold" v-if="!isPizza"><span class="bg-yellow-300">THAT'S IT!</span></p>
									<div class="space-y-1 font-semibold">
										<p>
											IMPORTANT: Please email us at
											<a :href="`mailto:${$store.state.brandInfo.billingEmail}`">{{ $store.state.brandInfo.billingEmail }}</a> for additional assistance.
										</p>
										<p>We will email you back as soon as possible. Please do NOT send multiple emails.</p>
										<p>Email us from the account email address you signed up with.</p>
									</div>
									<div class="py-10">
										<p>Please see how to send bitcoin from CoinBase</p>
										<p>
											<a href="https://support.coinbase.com/customer/en/portal/articles/971437-how-do-i-send-digital-currency-to-another-wallet-"
												>https://support.coinbase.com/customer/en/portal/articles/971437-how-do-i-send-digital-currency-to-another-wallet-</a
											>
										</p>
									</div>
								</div>
							</div>
							<hr />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'HowDoIRenewMySubscription',
	data() {
		return {
			title: this.$route.meta.title
		};
	},
	computed: {
		...mapState({
			isPizza: (state) => state.brandInfo.name === 'pizzatime'
		})
	}
};
</script>

<style scoped></style>
